import React, { useEffect, useState, useReducer, useMemo } from 'react';
import Card from 'components/Card';
import CreateGroupForm from 'components/GroupForm';
import useGroups from 'state/groups';
import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import INIT_STATE from 'components/GroupForm/initState';
import { useRouter } from 'providers/router';
import toSubmitObject from 'components/GroupForm/submitProcessor';
import FormScrollableContainer from 'pages/Group/containers/FormScrollableContainer';
import { useParams } from 'react-router';
import Reducer, { ACTIONS } from 'components/GroupForm/Reducer';
import parseFormData from './parseFormData';

const Edit = () => {
  const { translate } = useI18n();
  const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE);
  const [, dispatcher, utils] = useGroups();
  const [oldGroup, setOldGroup] = useState({});
  const { route, canGoBack } = useRouter();
  const { groupId } = useParams();

  const dispatch = (type, payload) => formDispatcher({ type, payload });

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  useEffect(()=>{
    if(!groupId) return;
    const group = utils.findOne(groupId);
    setOldGroup(group);
    dispatch(ACTIONS.INIT, parseFormData(group));
  },[groupId]);
  

  const handleSubmit = () => {
    const newGroup = toSubmitObject(formData);
    newGroup.id = groupId;
    if(newGroup.name === oldGroup.name) delete newGroup.name;
    dispatcher.updateStart(newGroup);
  };

  const routeString = useMemo(()=>{
    if(!!route && !!canGoBack){
      const crumbs = route.from.split('/');
      if(parseInt(crumbs[1]) === groupId)  return route.from;
    }

    return `/${groupId}/management/profile`;
  },[route, groupId]);

  return (
    <FormScrollableContainer>
      <IconLink to={routeString}>{translate('Back')}</IconLink>
      <div style={{ height: '32px' }}></div>
      <Card>
        <CreateGroupForm
          title={translate('Edit Group')}
          formData={formData}
          dispatch={dispatch}
          handleSubmit={handleSubmit}
          button={translate('Edit Group')}
        />
      </Card>
    </FormScrollableContainer>
  );
};

export default Edit;
